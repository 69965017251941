import React, { useState, useEffect, useRef } from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Content = () => {

    const [state, setState] = useState({
        currentIndex: null
    });

    function changeCurrentIndex(event, index) {
        // document.body.style.overflow = 'hidden';
        setState({
            currentIndex: index
        });
    };

    const node = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
        document.removeEventListener("mousedown", handleClick);
        };
      }, []);

    const handleClick = e => {
        if(node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click 
        // document.body.style.overflow = 'unset';
        setState({
            currentIndex: null
        });
    };

    return <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
        gallery: wordpressPage(slug: { eq: "gallery" }) {
            acf {
                gallery_title
                gallery_subtitle
                content
            }	
        }
        allWordpressWpGallery {
            edges {
                  node {
                      id
                      title
                      slug
                      featured_media {
                          source_url
                      }
                  }
              }
          }
    }
    `} render={props => (
        <div className="frame">
            <div className="gallery-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} />
                </div>
                <div className="title" data-aos="fade-up">{ props.gallery.acf.gallery_title }</div>
                <div className="subtitle" data-aos="fade-up">{ props.gallery.acf.gallery_subtitle }</div>
                {/* <div className="content" data-aos="fade-up" dangerouslySetInnerHTML = {{ __html: props.gallery.acf.content }}></div> */}
                <div className="gallery-wrapper">
                {
                    props.allWordpressWpGallery.edges.map((item, index) => (
                        <div className="item tiles" key={item.node.id} data-aos="fade-up" data-aos-delay={ index * 100 } data-aos-duration="1200">
                            <div className="itemhover">
                                <div className="image">
                                    <img onClick={(event) => changeCurrentIndex(event,index)} src={item.node.featured_media.source_url} />
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
            
            <div className="gallery-popup fade-in" key={state.currentIndex !== null ? props.allWordpressWpGallery.edges[state.currentIndex].node.id : null} style={{display: state.currentIndex !== null ? 'flex' : 'none' }}>
                <div className="gallery-popup-inner" ref={node}>
                    <img src={ state.currentIndex !== null ? props.allWordpressWpGallery.edges[state.currentIndex].node.featured_media.source_url : null} alt="" />
               </div>
            </div>

        </div>
    ) } />
};

export default Content;